import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentWidth from "../components/ContentWidth";
import Articles, { ArticlePreview } from "../components/articles";

const Article = ({ data }) => {
  const articles: ArticlePreview[] = data.allContentfulArticle.edges.map(
    (edge) => edge.node
  );
  return (
    <Layout menuColor={"black"}>
      <SEO title="Articles" />
      <ContentWidth>
        <Articles articles={articles} />
      </ContentWidth>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    allContentfulArticle(sort: { fields: publishedDate, order: DESC }) {
      edges {
        node {
          title
          id
          slug
          publishedDate(formatString: "Do MMMM, YYYY")
          featuredImage {
            fluid(maxWidth: 750) {
              ...GatsbyContentfulFluid
            }
          }
          excerpt {
            childMarkdownRemark {
              excerpt(pruneLength: 150)
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Article;
