import React, { FunctionComponent } from "react";
import { Link } from "gatsby-plugin-react-i18next";
import Img, { FluidObject } from "gatsby-image";
import * as styles from "./index.module.scss";

export interface ArticlePreview {
  id: string;
  title: string;
  slug: string;
  publishedDate: string;
  featuredImage: {
    fluid: FluidObject;
  };
  excerpt: any;
}

interface Props {
  articles: ArticlePreview[];
}

const Articles: FunctionComponent<Props> = ({ articles }) => {
  return (
    <div className={styles.articles}>
      <ul>
        {articles.map((article) => {
          return (
            <li className={styles.articleItem} key={article.id}>
              <div className={styles.imageWrapper}>
                {article.featuredImage && (
                  <Link to={`/articles/${article.slug}/`}>
                    <Img
                      className={styles.image}
                      fluid={article.featuredImage.fluid}
                      alt={article.title}
                      imgStyle={{ objectFit: "contain" }}
                    />
                  </Link>
                )}
              </div>
              <div>
                <h2 className={styles.title}>
                  <Link to={`/articles/${article.slug}/`}>{article.title}</Link>
                </h2>
                <div className={styles.published}>
                  <span>Posted on {article.publishedDate}</span>
                </div>
                {article.featuredImage && (
                  <p>{article.excerpt.childMarkdownRemark.excerpt}</p>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Articles;
