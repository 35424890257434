import React, { FunctionComponent } from "react"
import * as styles from "./index.module.scss";

const ContentWidth: FunctionComponent = ({children}) => {
  return (
    <div className={styles.content}>{children}</div>
  )
}

export default ContentWidth;
